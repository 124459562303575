import {attr} from "spraypaint";
import ApplicationRecord from "./baseModel";

const FAQModel = ApplicationRecord.extend({
	static: { jsonapiType: "frequently_asked_questions" },
	attrs: {
		id: attr(),
		createdAt: attr({ persist: false }),
		updatedAt: attr({ persist: false }),
		question: attr(),
		answer: attr(),
		position: attr(),
	}
})

export default FAQModel