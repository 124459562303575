import {useEffect, useState} from 'react';
import {getCurrentSCModel} from '../../../api/serviceConfigAPI';
import ProtectedWrapper from "../../../components/pageWrappers/protectedWrapper/protectedWrapper";
import AdminPageWrapper from "../../../components/pageWrappers/adminPageWrapper/adminPageWrapper";
import {ADMIN_ROLE} from "../../../helpers/constants/roles";
import {Button, Form, InputGroup, Alert} from "react-bootstrap";

const SharesPage = () => {
	const [serviceConfig, setServiceConfig] = useState(null);
	const [isEnable, setIsEnable] = useState(null);
	const [amount, setAmount] = useState('');
	const [serviceFee, setServiceFee] = useState(0);
	const [factor, setFactor] = useState('');
	const [validity_period, setValidity_period] = useState('');

	const getCurrentConfig = async () => {
		await getCurrentSCModel()
		.then(res => {
			setServiceConfig(res)
			setAmount(res?.freeDeliveryPromo.from_order_amount / 100);
			setIsEnable(res?.freeDeliveryPromo.enabled);
			setFactor(res?.bonusPoints?.factor * 100);
			setServiceFee(res?.serviceFee / 100);
			setValidity_period(res?.bonusPoints?.validity_period);
		});
	};

	useEffect(() => {
		getCurrentConfig()
	}, []);

	const updateConfigFreeDelivery = async () => {
	  serviceConfig.freeDeliveryPromo = {
			enabled: isEnable,
			from_order_amount: amount * 100
		}
		await serviceConfig.save()
		.then(() => {
			getCurrentConfig();
		})
	};

	const updateConfigBonus = async () => {
		serviceConfig.bonusPoints = {
			factor: factor / 100,
			validity_period: Number(validity_period)
		}
		await serviceConfig.save()
		.then(() => {
			getCurrentConfig();
		})
	};

	const updateServiceFee = async () => {
		serviceConfig.serviceFee = Number(serviceFee * 100);

		await serviceConfig.save()
		.then(() => {
			getCurrentConfig();
		})
	};

	return (
		<ProtectedWrapper role={ADMIN_ROLE}>
			<AdminPageWrapper>
				<div className="block marginBottom20">
					<h3 className="marginBottom20">Бесплатная доставка</h3>
					<Form.Check
						type="switch"
						id="custom-switch"
						label="Включить бесплатную доставку на платформе"
						checked={isEnable}
						onChange={() => setIsEnable(p => !p)}
					/>

					<div className="flex marginTop20">
						<InputGroup>
							<InputGroup.Text id="basic-addon2">Сумма с которой доставка будет бесплатной (в рублях)</InputGroup.Text>
							<Form.Control
								placeholder="Введите сумму"
								aria-label="Сумма с которой доставка будет бесплатной"
								aria-describedby="basic-addon2"
								value={amount}
								disabled={!isEnable}
								onChange={e => setAmount(e.target.value)}
							/>
						</InputGroup>
						{(
							serviceConfig?.freeDeliveryPromo?.enabled !== isEnable ||
							(Number(serviceConfig?.freeDeliveryPromo?.from_order_amount / 100)) !== (Number(amount))
						) && (
							<Button
								style={{width: '300px'}}
								variant="dark"
								onClick={() => updateConfigFreeDelivery()}
							>
								Сохранить
							</Button>
						)}
					</div>
				</div>

				<div className="block marginBottom20">
					<h3 className="marginBottom20">Бонусные баллы </h3>

					<div className="flex-column marginTop20">
						<InputGroup className="marginBottom10">
							<InputGroup.Text id="basic-addon2">Доля от суммы заказа, которая будет преобразована в бонусные баллы для покупателя</InputGroup.Text>
							<Form.Control
								placeholder="Введите процент, например 10" // делить на 100
								aria-label="Доля от суммы заказа"
								aria-describedby="basic-addon2"
								value={factor}
								onChange={e => setFactor(e.target.value)}
							/>
						</InputGroup>
						<InputGroup className="marginBottom10">
							<InputGroup.Text id="basic-addon2">Cрок действия бонусных баллов в днях (Дата применется только к новым баллам)</InputGroup.Text>
							<Form.Control
								placeholder="Введите срок, в днях"
								aria-label="Срок в днях"
								aria-describedby="basic-addon2"
								value={validity_period}
								onChange={e => setValidity_period(e.target.value)}
							/>
						</InputGroup>

						<Button
							style={{width: '300px'}}
							variant="dark"
							onClick={() => updateConfigBonus()}
						>
							Сохранить
						</Button>
					</div>
				</div>

				<div className="block">
					<h3 className="marginBottom20">Сервисный сбор</h3>

					<InputGroup className="marginBottom10">
						<InputGroup.Text id="basic-addon2">Сервисный сбор в рублях</InputGroup.Text>
						<Form.Control
							type="number"
							placeholder="Введите сумму"
							aria-describedby="basic-addon2"
							value={serviceFee}
							onChange={e => setServiceFee(e.target.value)}
						/>
					</InputGroup>

					<Button
						style={{width: '300px'}}
						variant="dark"
						disabled={!serviceFee}
						onClick={() => updateServiceFee()}
					>
						Сохранить
					</Button>
				</div>

			</AdminPageWrapper>
		</ProtectedWrapper>
	)
}

export default SharesPage