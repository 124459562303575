import AdminChatModel from "../models/adminChatModel";

const getAllAdminChats = async (role) => {
	const WHERE = role === 'all'
		? {}
		: {owningUserRole: role};

	const res = await AdminChatModel
	.includes(['user', 'chat_messages'])
	.where(WHERE)
	.extraParams({ paginate: false})
	.all();

	return res.data;
};

const searchAdminChats = async (role, searchStr) => {
	const WHERE = role === 'all'
		? {'user.name': searchStr}
		: {owningUserRole: role, 'user.name': searchStr};

	const res = await AdminChatModel
	.includes(['user', 'chat_messages'])
	.where(WHERE)
	.extraParams({ paginate: false})
	.all();

	return res.data;
};

const getAdminChat = async (chatId) => {
	const res = await AdminChatModel
	.includes(['user', {chat_messages : 'user'}])
	.find(chatId)

	return res.data;
};

export {
	getAllAdminChats,
	getAdminChat,
	searchAdminChats
}