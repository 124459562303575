import {useEffect, useState} from "react";
import { ReactSortable } from "react-sortablejs";
import {Button, Form, InputGroup, Badge} from "react-bootstrap";
import ProtectedWrapper from "../../../components/pageWrappers/protectedWrapper/protectedWrapper";
import AdminPageWrapper from "../../../components/pageWrappers/adminPageWrapper/adminPageWrapper";
import {ADMIN_ROLE} from "../../../helpers/constants/roles";
import {getAllQuestions} from "../../../api/faqAPI";
import FAQModel from "../../../models/faqModel";
import './prioritizationPage.scss';
import {getAllMarketplaces} from "../../../api/marketplaceAPI";

const PrioritizationPage = () => {
	const [currentMarket, setCurrentMarket] = useState(null);
	const [markets, setMarkets] = useState([]);

	const getMarkets = async () => {
		await getAllMarketplaces()
		.then(res => {
			setMarkets(res);
		})
	}

	useEffect(() => {
		getMarkets();
	}, []);

	const sortHandler = async (newIndex) => {
		currentMarket.position = newIndex + 1;
		await currentMarket.save()
		.then(() => {
			setCurrentMarket(null);
			getMarkets();
		})
	};

	console.log(currentMarket);


	return (
		<ProtectedWrapper role={ADMIN_ROLE}>
			<AdminPageWrapper>
				<div className="flex-space-between marginBottom20">
					<h3>Приоритезация плащадок</h3>
				</div>
				<div className="block">
					<Badge className="marginBottom20" bg="info">
						<h6>Для изменения порядка площадок в каталоге, нажмите на площадку и не отпуская потяните и поставте на нужную позицию</h6>
					</Badge>
					<ReactSortable
						dragClass="sortableDrag"
						list={markets}
						setList={() => {}}
						animation="200"
						easing="ease-out"
						onEnd={(e) => {
							sortHandler(e.newIndex)
						}}
					>
						{markets.map((m) => (
							<div key={`${m.id}_${m.name}`} className="Item flex-space-between" onMouseDown={() => {setCurrentMarket(m)}}>
								<span>{m.name}</span>
								<span>{m.position ? `Текущая позиция в каталоге ${m.position}` : 'Позиция не задана'}</span>
							</div>
						))}
					</ReactSortable>
				</div>
			</AdminPageWrapper>
		</ProtectedWrapper>
	)
}

export default PrioritizationPage;