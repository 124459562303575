import {attr, hasMany} from "spraypaint";
import ApplicationRecord from "./baseModel";

const CouponModel = ApplicationRecord.extend({
	static: { jsonapiType: "coupons" },
	attrs: {
		id: attr(),
		code: attr(),
		discountRatio: attr(),
		notificationText: attr(),
		status: attr(),
		validUntil: attr(),
		createdAt: attr({ persist: false }),
		updatedAt: attr({ persist: false }),

		marketplaceCoupons: hasMany(),
		couponUsages: hasMany(),
	}
})

export default CouponModel