import {Button, Form} from "react-bootstrap";

const PositionBanner = ({currentBigBanner, closeHandler, changePositionHandler, resetHandler}) => {
	return (
		<div className="block marginBottom20">
			<div className="flex-space-between marginBottom20">
				<h4>{`Редактирование отображения баннера - ${currentBigBanner.name}`}</h4>
				<Button variant="dark" onClick={() => closeHandler()}>Закрыть</Button>
			</div>
			<Form.Check
				type="switch"
				id="custom-switch"
				className="marginBottom10"
				label="Добавление большого баннера на 'Главный экран'"
				checked={currentBigBanner?.appLayouts?.includes('main') || false}
				disabled={currentBigBanner?.appLayouts?.includes('main') || false}
				onChange={() => changePositionHandler('main')}
			/>
			<Form.Check
				type="switch"
				id="custom-switch"
				className="marginBottom10"
				label="Добавление большого баннера на экран 'Рестораны' (добавляется в конец)"
				checked={currentBigBanner?.appLayouts?.includes('restaurants') || false}
				disabled={currentBigBanner?.appLayouts?.includes('restaurants') || false}
				onChange={() => changePositionHandler('restaurants')}
			/>
			<Form.Check
				type="switch"
				id="custom-switch"
				className="marginBottom10"
				label="Добавление большого баннера на экран 'Магазины' (добавляется в конец)"
				checked={currentBigBanner?.appLayouts?.includes('stores') || false}
				disabled={currentBigBanner?.appLayouts?.includes('stores') || false}
				onChange={() => changePositionHandler('stores')}
			/>

			<Button
				variant="dark"
				className="marginBottom10"
				style={{width: '100%'}}
				onClick={() => resetHandler()}
			>
				Сбросить
			</Button>
		</div>
	)
};

export default PositionBanner;
