import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import DatePicker, {registerLocale} from "react-datepicker";
import {
	Button,
	Form,
	InputGroup,
	Tabs,
	Tab,
	TabContent,
	Modal,
	Spinner,
	ButtonGroup,
	Badge,
	Table
} from "react-bootstrap";
import {ru} from "date-fns/locale/ru";
import ProtectedWrapper from "../../../components/pageWrappers/protectedWrapper/protectedWrapper";
import AdminPageWrapper from "../../../components/pageWrappers/adminPageWrapper/adminPageWrapper";
import DropZoneUploader from "../../../components/dropZoneUploader/dropZoneUploader";
import {getUser} from "../../../api/userAPI";
import {getOrdersForCourier} from "../../../api/orderAPI";
import {attachHandler, uploadHandler} from "../../../helpers/directUpload";
import {ADMIN_ROLE, COURIER_ROLE, MERCHANT_ROLE, USER_ROLE} from "../../../helpers/constants/roles";
import './courierPage.scss';
import {DELIVERE_STATUSES, OREDER_STATUSES} from "../../../helpers/constants/statuses";
import {format} from "date-fns";
import PaginationComponent from "../../../components/pagination/pagination";

const CourierPage = () => {
	registerLocale('ru', ru);
	const navigate = useNavigate();
	const {courierId} = useParams();
	const [avatar, setAvatar] = useState('');
	const [orders, setOrders] = useState([]);
	const [total, setTotal] = useState([]);
	const [activePage, setActivePage] = useState(1);
	const [currentUser, setCurrentUser] = useState(null);
	const [formData, setFormData] = useState({
		name: '',
		surname: '',
		patronym: '',
		phone: '',
		citizenship: '',
		contractNumber: '',
		password: '',
		gender: 'male',
		birthday: new Date()
	});
	const [errors, setErrors] = useState({
		phone: null
	})

	const getAllOrders = async (pageNumber) => {
		await getOrdersForCourier(courierId, pageNumber || activePage,)
		.then(res => {
			setOrders(res.data);
			setTotal(res.total);
		})
	}

	const getCurrentUser = async () => {
		await getUser(courierId)
		.then(res => {
			setCurrentUser(res);
			setFormData({
				name: res.name,
				surname: res.surname,
				patronym: res.patronym,
				citizenship: res.legalData?.citizenship || '',
				contractNumber: res.legalData?.contract_number || '',
				phone: res.phone,
				password: res.password,
				gender: res.gender,
				birthday: new Date(res.birthday)
			});
			setAvatar(res.avatarUrl ? `${window?.__CONFIG__?.REACT_APP_BASE_URL}${res.avatarUrl}` : '')
		})
	}

	useEffect(() => {
		getCurrentUser();
		getAllOrders();
	}, []);

	const handlePageChange = (pageNumber) => {
		setActivePage(pageNumber);
		getAllOrders(pageNumber);
	};

	const changeHandler = (field, value) => {
		setFormData(p => ({...p, [field]: value}));
	};

	const attachAvatarHandler = (file) => {
		attachHandler(file, (f, c) => setAvatar({ file: f, checkSum: c }));
	};

	const submitHandler = async () => {
		currentUser.name = formData.name;
		currentUser.surname = formData.surname;
		currentUser.patronym = formData.patronym;
		currentUser.legalData = {
			contract_number: formData.contractNumber,
			citizenship: formData.citizenship
		}
		currentUser.phone = formData.phone;
		currentUser.gender = formData.gender;
		currentUser.birthday = formData.birthday;

		if (avatar?.file) {
			currentUser.avatar = avatar && (await uploadHandler(avatar));
		}

		await currentUser.save()
		.then(() => {
			if (Object.keys(currentUser.errors).length > 0) {
				setErrors(currentUser.errors);
			} else {
				navigate('/admin/couriers')
			}
		})
	};

	return (
		<ProtectedWrapper role={ADMIN_ROLE}>
			<AdminPageWrapper>
				<div>
					<Button
						variant="dark"
						onClick={() => navigate('/admin/couriers')}
						className="marginBottom20"
					>Назад</Button>
					<div className="flex-space-between marginBottom20">
						<h3>Информация о курьере</h3>
						<Button variant="dark" onClick={() => {navigate(`/admin/chats?with=courier`)}}>
							Связаться с курьером
						</Button>
					</div>
					<div className="block marginBottom20">
						{/*<p className="marginBottom10">*/}
						{/*	{`Дата создания аккаунта курьера ${format(new Date(currentUser ? currentUser?.createdAt : ''), 'dd.MM.yyyy - hh:mm')}`}*/}
						{/*</p>*/}
						<p className="marginBottom10">
							Доступен для распределения заказов:
							<Badge className="marginLeft10" bg={currentUser?.availableForOrderDistribution ? "success" : "danger"}>
								{currentUser?.availableForOrderDistribution ? 'Доступен' : 'Недоступен'}
							</Badge>
						</p>
						<p>
							Заказов на курьере в данный момент:
							<Badge className="marginLeft10" bg="info">
								{currentUser?.courierActiveOrdersCount}
							</Badge>
						</p>
					</div>
					<div className="marginBottom20">
						<DropZoneUploader
							preview={avatar}
							acceptPreviewHandler={(f) => {
								if (f) attachAvatarHandler(f[0]);
							}}
							className="marginBottom20"
							text="Прикрепите аватар курьера в формате jpeg или png"
						/>
					</div>
					<div className="grid3x marginBottom20">
						<InputGroup>
							<InputGroup.Text>Фамилия</InputGroup.Text>
							<Form.Control
								placeholder="Введите фамилию"
								onChange={e => changeHandler('surname', e.target.value)}
								value={formData.surname}
							/>
						</InputGroup>
						<InputGroup>
							<InputGroup.Text>Имя</InputGroup.Text>
							<Form.Control
								placeholder="Введите имя"
								onChange={e => changeHandler('name', e.target.value)}
								value={formData.name}
							/>
						</InputGroup>
						<InputGroup>
							<InputGroup.Text>Отчество</InputGroup.Text>
							<Form.Control
								placeholder="Введите отчество"
								onChange={e => changeHandler('patronym', e.target.value)}
								value={formData.patronym}
							/>
						</InputGroup>
					</div>
					<div className="grid3x marginBottom20">
						<InputGroup style={{ flexWrap: 'nowrap'}}>
							<InputGroup.Text>Дата рождения</InputGroup.Text>
							<DatePicker
								locale="ru"
								selected={formData.birthday}
								onChange={(date) => changeHandler('birthday', date)}
								wrapperClassName="DatePikerWrapper"
								className="DatePikerInput"
								dateFormat="dd.MM.yyyy"
							/>
						</InputGroup>
						<InputGroup style={{ flexWrap: 'nowrap'}}>
							<InputGroup.Text>Пол</InputGroup.Text>
							<ButtonGroup style={{width: '100%'}}>
								<Button
									onClick={() => changeHandler('gender', 'male')}
									variant={formData.gender === 'male' ? "dark" : "secondary"}>Мужчина</Button>
								<Button
									onClick={() => changeHandler('gender', 'female')}
									variant={formData.gender === 'female' ? "dark" : "secondary"}>Женщина</Button>
							</ButtonGroup>
						</InputGroup>
						<InputGroup>
							<InputGroup.Text>Телефон</InputGroup.Text>
							<Form.Control
								placeholder="Введите телефон"
								onChange={e => changeHandler('phone', e.target.value)}
								value={formData.phone}
								isInvalid={!!errors.phone}
							/>
							<Form.Control.Feedback type="invalid">
								{errors?.phone?.fullMessage}
							</Form.Control.Feedback>
						</InputGroup>
					</div>
					<div className="grid2x marginBottom20">
						<InputGroup>
							<InputGroup.Text>Гражданство</InputGroup.Text>
							<Form.Control
								placeholder="Введите Гражданство"
								onChange={e => changeHandler('citizenship', e.target.value)}
								value={formData.citizenship}
							/>
						</InputGroup>
						<InputGroup>
							<InputGroup.Text>Номер договора</InputGroup.Text>
							<Form.Control
								placeholder="Введите номер договора"
								onChange={e => changeHandler('contractNumber', e.target.value)}
								value={formData.contractNumber}
							/>
						</InputGroup>
					</div>
					<Button
						onClick={() => submitHandler()}
						variant="dark"
						style={{width: '100%', marginBottom: '40px'}}
						disabled={
							formData.role === ADMIN_ROLE || formData.role === MERCHANT_ROLE
								? !formData.email
								: formData.role === COURIER_ROLE || formData.role === USER_ROLE
									? !formData.phone
									: false
						}
					>Сохранить данные курьера</Button>
				</div>
				<h3 className="marginBottom20">Заказы курьера</h3>
				<Table>
					<thead>
					<tr>
						<th>Номер заказа</th>
						<th>Статус заказа</th>
						<th>Тип доставки</th>
						<th>Дата создания заказа</th>
						<th>Адрес выдачи</th>
					</tr>
					</thead>
					<tbody>
					{orders.map(order => (
						<tr key={order.id}>
							<td
								onClick={() => navigate(`/admin/orders/${order.id}`)}
								style={{textDecoration: 'underline', cursor: 'pointer'}}
							>
								{order.publicUid || 'Номер отсутствует'}
							</td>
							<td>{OREDER_STATUSES[order.status].translate}</td>
							<td>{DELIVERE_STATUSES[order.deliveryType].translate}</td>
							<td>{format(new Date(order.createdAt), 'dd.MM.yyyy - hh:mm')}</td>
							<td>{order?.pickupLocation?.address || 'Адрес выдачи не указан'}</td>
						</tr>
					))}
					</tbody>
				</Table>
				<PaginationComponent
					total={total}
					activePage={activePage}
					handlePageChange={(pn) => handlePageChange(pn)}
				/>
			</AdminPageWrapper>
		</ProtectedWrapper>
	)
}

export default CourierPage;