import {attr, belongsTo, hasMany} from "spraypaint";
import ApplicationRecord from "./baseModel";
import ChatMessageModel from "./chatMessageModel";

const AdminChatModel = ApplicationRecord.extend({
	static: { jsonapiType: "admin_user_chat_rooms" },
	attrs: {
		id: attr(),
		owningUserRole: attr(),
		createdAt: attr({ persist: false }),
		updatedAt: attr({ persist: false }),
		user: belongsTo(),
		chatMessages: hasMany(ChatMessageModel),
	}
})

export default AdminChatModel