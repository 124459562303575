import {Button, Form, InputGroup} from "react-bootstrap";
import DropZoneUploader from "../../../../components/dropZoneUploader/dropZoneUploader";

const CreateBlock = ({
	editState,
	changeHandler,
	errors,
	markets,
	attachAvatarHandler,
	submitHandler,
	closeHandler
}) => {
	return (
		<div className="block marginBottom20">
			<div className="flex-space-between marginBottom20">
				<h4>{editState.mode === 'create' ? 'Создание баннера' : `Редактирование баннера - ${editState.data.name}`}</h4>
				<Button variant="dark" onClick={() => closeHandler()}>Закрыть</Button>
			</div>
			<div className="grid3x marginBottom10">
				<InputGroup>
					<InputGroup.Text>Название</InputGroup.Text>
					<Form.Control
						placeholder="Название"
						onChange={e => changeHandler('name', e.target.value)}
						value={editState.data.name}
						isInvalid={errors.name}
					/>
					<Form.Control.Feedback type="invalid">
						{errors?.name}
					</Form.Control.Feedback>
				</InputGroup>
				<InputGroup>
					<InputGroup.Text>Тип баннера</InputGroup.Text>
					<Form.Select
						aria-label="Выберите тип"
						aria-placeholder="Выберите тип"
						onChange={e => changeHandler('bannerType', e.target.value)}
						value={editState.data.bannerType}
					>
						<option value='regular'>Обычный</option>
						<option value='big'>Большой</option>
					</Form.Select>
				</InputGroup>
				<InputGroup>
					<InputGroup.Text>Позиция</InputGroup.Text>
					<Form.Control
						placeholder="Позиция"
						onChange={e => changeHandler('position', e.target.value)}
						value={editState.data.position}
						isInvalid={errors.position}
					/>
					<Form.Control.Feedback type="invalid">
						{errors?.position}
					</Form.Control.Feedback>
				</InputGroup>
			</div>
			<div className="grid2x marginBottom10">
				<InputGroup>
					<InputGroup.Text>Маркетплейс</InputGroup.Text>
					<Form.Select
						aria-label="Выберите маркетплейс"
						aria-placeholder="Выберите маркетплейс"
						onChange={e => changeHandler('marketplaceId', e.target.value)}
						value={editState.data.marketplaceId}
						isInvalid={errors.marketplaceId}
					>
						<option value='all' disabled>Выберите маркетплейс</option>
						{markets.map(m => (<option key={m.id} value={m.id}>{m.name}</option>))}
					</Form.Select>
					<Form.Control.Feedback type="invalid">
						{errors?.marketplaceId}
					</Form.Control.Feedback>
				</InputGroup>
				<InputGroup>
					<InputGroup.Text>Тип ссылки</InputGroup.Text>
					<Form.Select
						aria-label="Выберите тип"
						aria-placeholder="Выберите тип"
						onChange={e => changeHandler('linkType', e.target.value)}
						value={editState.data.linkType}
					>
						<option value='external_link'>Ссылка</option>
						<option value='marketplace'>Маркетплейс</option>
					</Form.Select>
				</InputGroup>
			</div>
			{editState.data.linkType === 'external_link' && (
				<InputGroup className="marginBottom10">
					<InputGroup.Text>Ссылка баннера</InputGroup.Text>
					<Form.Control
						placeholder="Ссылка баннера"
						onChange={e => changeHandler('externalLink', e.target.value)}
						value={editState.data.externalLink}
						isInvalid={errors.externalLink}
					/>
					<Form.Control.Feedback type="invalid">
						{errors?.externalLink}
					</Form.Control.Feedback>
				</InputGroup>
			)}
			<DropZoneUploader
				preview={editState.data.image}
				acceptPreviewHandler={(f) => {
					if (f) attachAvatarHandler(f[0]);
				}}
				error={errors.image}
				className="marginBottom10"
				text="Прикрепите баннер в формате jpeg или png"
			/>
			<Button variant="dark" style={{width: '100%'}} onClick={() => submitHandler()}>Сохранить</Button>
		</div>
	)
};

export default CreateBlock;