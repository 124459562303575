import CouponModel from "../models/couponModel";

const getAllCoupons = async () => {
	const res = await CouponModel
	.extraParams({ paginate: false})
	.includes([{marketplaceCoupons: ['marketplace']}])
	.all();

	return res.data;
};

export {
	getAllCoupons,
}