import {useEffect, useState} from "react";
import {ADMIN_ROLE} from "../../../helpers/constants/roles";
import {Button, Form, InputGroup, Badge} from "react-bootstrap";
import ProtectedWrapper from "../../../components/pageWrappers/protectedWrapper/protectedWrapper";
import AdminPageWrapper from "../../../components/pageWrappers/adminPageWrapper/adminPageWrapper";
import './bannersPage.scss';
import {getAllBanners, getBanner} from "../../../api/bannerAPI";
import {getAllMarketplaces} from "../../../api/marketplaceAPI";
import {attachHandler, uploadHandler} from "../../../helpers/directUpload";
import BannerModel from "../../../models/bannerModel";
import CreateBlock from "./content/createBlock";
import {getCurrentSCModel} from "../../../api/serviceConfigAPI";
import {useError} from "../../../helpers/context/errorContext";
import PositionBanner from "./content/positionBanner";

const initialEditState = {
	open: false,
	mode: 'create',
	data: {
		name: '',
		bannerType: 'regular',
		linkType: 'marketplace',
		position: '',
		marketplaceId: 'all',
		image: null,
		externalLink: '',
	}
};

const initialErrorState = {
	name: null,
	position: null,
	marketplaceId: null,
	externalLink: null,
};

const BannersPage = () => {
	const { setNewError } = useError();
	const [filterMarket, setFilterMarket] = useState('all');
	const [banners, setBanners] = useState([]);
	const [config, setConfig] = useState({});
	const [currentBanners, setCurrentBanner] = useState(null);
	const [currentBigBanners, setCurrentBigBanner] = useState(null);
	const [markets, setMarkets] = useState([]);
	const [editState, setEditState] = useState(initialEditState);
	const [errors, setErrors] = useState(initialErrorState);

	const getBanners = async (filterMarket) => {
		await getAllBanners(filterMarket)
		.then(res => {
			setBanners(res)
		})
	};

	const getCurrentBigBanners = async (bannerId) => {
		await getBanner(bannerId)
		.then(res => {
			setCurrentBigBanner(res)
		})
	};

	const getMarkets = async () => {
		await getAllMarketplaces()
		.then(res => setMarkets(res))
	};

	const getConfig = async () => {
		await getCurrentSCModel()
		.then(res => {
			setConfig(res);
		})
	};

	useEffect(() => {
		getBanners(filterMarket);
	}, [filterMarket]);

	useEffect(() => {
		getMarkets();
		getConfig();
	}, []);

	const changeHandler = (key, value) => {
		setEditState(p => ({...p, data: {...p.data, [key]: value}}));
	};

	const attachAvatarHandler = (file) => {
		attachHandler(file, (f, c) => changeHandler('image', { file: f, checkSum: c }));
	};

	const publishBanner = async (banner) => {
		banner.isPublished = !banner.isPublished;
		await banner.save()
		.then(() => {
			getBanners();
		})
	};

	const deleteHandler = async (banner) => {
		await banner.destroy()
		.then(() => {
			getBanners();
		})
	}

	const startEditHandler = (banner) => {
		setEditState({
			open: true,
			mode: 'edit',
			data: {
				name: banner?.name,
				bannerType: banner?.bannerType,
				linkType: banner?.linkType,
				position: banner?.position,
				marketplaceId: banner?.marketplace?.id,
				image: `${window?.__CONFIG__?.REACT_APP_BASE_URL}${banner?.imageUrl}`,
				externalLink: banner?.externalLink,
			}
		});
		setErrors(initialErrorState)
	};

	const submitHandler = async () => {
		if (
			!editState.data.name || !editState.data.position || !editState.data.image ||
			!editState.data.marketplaceId || (editState.data.linkType === 'external_link' && !editState.data.externalLink)
		) {
			setErrors({
				name: !editState.data.name ? 'Обязательное имя' : null,
				image: !editState.data.image ? 'Обязательное изображение' : null,
				position: !editState.data.position ? 'Обязательное имя' : null,
				marketplaceId: (editState.data.marketplaceId === 'all') ? 'Необходимо выбрать маркетплейс' : null,
				externalLink: !editState.data.externalLink ? 'Обязательное имя' : null,
			})
		} else {
			const banner = editState.mode === 'create' ? new BannerModel() : currentBanners;
			banner.name = editState.data.name;
			banner.bannerType = editState.data.bannerType;
			banner.linkType = editState.data.linkType;
			banner.position = editState.data.position;
			banner.marketplace = markets.find(m => m.id === editState.data.marketplaceId);

			if (editState.data.linkType === 'external_link') {
				banner.externalLink = editState.data.externalLink;
			}

			if (editState.data.image?.file) {
				banner.image = editState.data.image && (await uploadHandler(editState.data.image));
			}

			await banner.save({ with: "marketplace.id"})
			.then(async () => {
				getBanners();
				setEditState(initialEditState);
				setErrors(initialErrorState)
			})
		}
	};

	const changePositionBanner = async (type) => {
		if (type === 'main') {
			config.addBannerToMainLayout = currentBigBanners?.id;
		}
		if (type === 'restaurants') {
			config.addBannerToRestaurantsLayout = currentBigBanners?.id;
		}
		if (type === 'stores') {
			config.addBannerToStoresLayout = currentBigBanners?.id;
		}

		await config.save()
		.then((res) => {
			if (!res) {
				setNewError({
					isShow: true,
					text: 'Необходимо опубликовать баннер перед редактированием'
				});
			} else {
				getBanners();
				getCurrentBigBanners(currentBigBanners.id);
			}
		})
	};

	const resetPositionBanner = async () => {
		config.removeBannerFromLayout = currentBigBanners.id;

		await config.save()
		.then(() => {
			getCurrentBigBanners();
			getBanners();
		})
	}

	return (
		<ProtectedWrapper role={ADMIN_ROLE}>
			<AdminPageWrapper>
				<div className="flex-space-between marginBottom20">
					<h3>Баннеры</h3>
					<Button
						variant='success'
						onClick={() => {
							setEditState(p => ({
								open: true,
								mode: 'create',
								data: p.data
							}))
						}}
						disabled={editState.open}
					>Создать баннер</Button>
				</div>
				{editState.open && (
					<CreateBlock
						editState={editState}
						closeHandler={() => setEditState(initialEditState)}
						changeHandler={(k,v) => changeHandler(k,v)}
						errors={errors}
						markets={markets}
						attachAvatarHandler={(f) => attachAvatarHandler(f)}
						submitHandler={() => submitHandler()}
					/>
				)}
				{currentBigBanners && (
					<PositionBanner
						currentBigBanner={currentBigBanners}
						closeHandler={() => setCurrentBigBanner(null)}
						changePositionHandler={(p) => changePositionBanner(p)}
						resetHandler={() => resetPositionBanner()}
					/>
				)}
				<div className="marginBottom20">
					<InputGroup>
						<InputGroup.Text>Выберите маркетплейс, баннеры которого отобразить</InputGroup.Text>
						<Form.Select
							aria-label="Выберите маркетплейс"
							aria-placeholder="Выберите маркетплейс"
							onChange={e => setFilterMarket(e.target.value)}
							value={filterMarket}
						>
							<option value='all'>Все маркетплейсы</option>
							{markets.map(m => (
								<option key={`${m.id}+${m.name}`} value={m.id}>{m.name}</option>
							))}
						</Form.Select>
					</InputGroup>
				</div>
				<div className="grid2x">
					{banners.map(banner => (
						<div key={banner?.id} className="block">
							<div className="flex-space-between">
								<h5 className="marginBottom10"><b>{banner?.name}</b></h5>
								<h6 className="marginBottom10">
									<Badge bg={banner.isPublished ? "success" : "danger"}>{banner.isPublished ? 'Опубликован' : 'Не опубликован'}</Badge>
								</h6>
							</div>
							<h6 className="marginBottom10">Маркетплейс: <b>{banner?.marketplace?.name || ''}</b></h6>
							<h6 className="marginBottom10">Тип баннера: <b>{banner.bannerType === 'regular' ? 'Обычный' : 'Большой'}</b></h6>
							<h6 className="marginBottom10">Позиция в списке: <b>{banner.position || ''}</b></h6>
							<div>
								<Button
									variant="dark"
									className="marginBottom10"
									style={{width: '100%'}}
									onClick={() => publishBanner(banner)}
								>
									{banner.isPublished ? 'Снять с публикации' : 'Опубликовать'}
								</Button>
								{banner.bannerType === 'big' && (
									<Button
										variant="dark"
										className="marginBottom10"
										style={{width: '100%'}}
										onClick={() => setCurrentBigBanner(banner)}
									>
										Выбрать где отображать баннер
									</Button>
								)}
								<div className="flex-space-between">
									<Button
										variant="dark"
										style={{width: '100%'}}
										onClick={() => {
											startEditHandler(banner);
											setCurrentBanner(banner);
										}}
									>Редактировать</Button>
									<Button
										variant="dark"
										style={{width: '100%'}}
										onClick={() => deleteHandler(banner)}
									>Удалить</Button>
								</div>
							</div>
						</div>
					))}
				</div>
				{banners.length === 0 && (
					<div className="flex-center">
						<h3>Баннеров не найдено</h3>
					</div>
				)}
			</AdminPageWrapper>
		</ProtectedWrapper>
	)
}

export default BannersPage;