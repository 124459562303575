import FAQModel from "../models/faqModel";

const getAllQuestions= async () => {
	const res = await FAQModel
	.extraParams({ paginate: false})
	.all();
	return res.data;
};

export {
	getAllQuestions
}