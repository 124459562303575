import BannerModel from "../models/bannerModel";

const getAllBanners = async (filterMarket) => {
	const WHERE = filterMarket === 'all' ? {} : {
		marketplaceId: filterMarket
	};

	const res = await BannerModel
	.includes('marketplace')
	.selectExtra(['appLayouts'])
	.extraParams({ paginate: false})
	.where(WHERE)
	.all();

	return res.data;
};

const getBanner = async (bannerId) => {
	const res = await BannerModel
	.includes('marketplace')
	.selectExtra(['appLayouts'])
	.find(bannerId)

	return res.data;
};

export {
	getAllBanners,
	getBanner
}