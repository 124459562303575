import {attr, belongsTo} from "spraypaint";
import ApplicationRecord from "./baseModel";
import isEqual from "lodash.isequal";

const dirtyChecker = (prior, current) => !(isEqual(prior, current))

const BannerModel = ApplicationRecord.extend({
	static: { jsonapiType: "banners" },
	attrs: {
		id: attr(),
		name: attr(),
		bannerType: attr(),
		linkType: attr(),
		position: attr(),
		isPublished: attr(),
		appLayouts: attr({dirtyChecker: dirtyChecker}),
		image: attr(),
		imageUrl: attr(),
		externalLink: attr(),
		marketplace: belongsTo()
	}
})

export default BannerModel