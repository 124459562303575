import ProtectedWrapper from "../../../components/pageWrappers/protectedWrapper/protectedWrapper";
import AdminPageWrapper from "../../../components/pageWrappers/adminPageWrapper/adminPageWrapper";
import ActiveChat from "../../../components/chat/activeChat/activeChat";
import ChatListItem from "../../../components/chat/listItem/chatListItem";
import {ADMIN_ROLE} from "../../../helpers/constants/roles";
import {getAllAdminChats, searchAdminChats} from '../../../api/adminChatsAPI';
import './chatsPage.scss';
import {useEffect, useState} from "react";
import {Button, Form, InputGroup, Spinner} from "react-bootstrap";

const ChatsPage = ({cable}) => {
	const [chats, setChats] = useState([]);
	const [role, setRole] = useState(window.location.search.replace('?with=', '') || 'all');
	const [searchStr, setSearchStr] = useState('');
	const [isLoading, setLoading] = useState(true);
	const [activeChatId, setActiveChatId] = useState(null);
	const [selectedChat, setSelectedChat] = useState(null);

	const getChats = async () => {
		await getAllAdminChats(role)
		.then(res => {
			setChats(res);
			setLoading(false);
		})
	}

	const searchHandler = async () => {
		if (searchStr.length > 0) {
			setLoading(true);
			await searchAdminChats(role, searchStr)
			.then(res => {
				setChats(res);
				setLoading(false);
			})
		}
	};

	const resetHandler = async () => {
		setSearchStr('');
		setLoading(true);
		getChats();
	};

	useEffect(() => {
		getChats();
	},[role]);


	return (
		<ProtectedWrapper role={ADMIN_ROLE}>
			<AdminPageWrapper>
				<div className="flex chatsRoot" style={{columnGap: '12px'}}>
					<div className="flex-column" style={{width: '400px'}}>
						<Form.Select
							aria-label="Выберите маркетплейс"
							aria-placeholder="Выберите маркетплейс"
							onChange={e => setRole(e.target.value)}
							value={role}
							className="marginBottom10"
						>
							<option value='all'>Все чаты</option>
							<option value='courier'>Чаты с курьерами</option>
							<option value='merchant'>Чаты с продавцами</option>
							<option value='user'>Чаты с клиентами</option>
						</Form.Select>
						<InputGroup className="marginBottom20">
							<Form.Control
								aria-label="Поиск"
								placeholder="Поиск по чатам"
								onChange={e => setSearchStr(e.target.value)}
								value={searchStr}
							/>
							<Button
								variant="dark"
								onClick={() => searchHandler()}
							>Найти</Button>
							{searchStr && (
								<Button
									variant="dark"
									onClick={() => resetHandler()}
								>Сбросить</Button>
							)}
						</InputGroup>
						<div className="chatsList">
							{isLoading ? (
								<Spinner style={{position: "absolute", top: '50%', left: '50%', marginTop: '40px'}} />
							) : (
								chats.map(chat => (
									<ChatListItem
										key={`Chat_${chat?.id}`}
										chat={chat}
										onClickItem={() => {
											setSelectedChat(chat);
											setActiveChatId(chat.id);
										}}
										isActive={activeChatId === chat.id}
									/>))
							)}
						</div>
					</div>
					<div className="block activeChatRoot">
						{activeChatId && (
							<ActiveChat
								cable={cable}
								activeChatId={activeChatId}
								selectedChat={selectedChat}
							/>
						)}
					</div>
				</div>
			</AdminPageWrapper>
		</ProtectedWrapper>
	)
};

export default ChatsPage