import {attr, belongsTo} from "spraypaint";
import ApplicationRecord from "./baseModel";

const ChatMessageModel = ApplicationRecord.extend({
	static: { jsonapiType: "chat_messages" },
	attrs: {
		id: attr(),
		content: attr(),
		createdAt: attr({ persist: false }),
		updatedAt: attr({ persist: false }),
		user: belongsTo(),
		adminUserChatRoom: belongsTo(),
	}
})

export default ChatMessageModel