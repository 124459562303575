import {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {Badge, Button, Modal, Spinner} from "react-bootstrap";
import MerchantPageWrapper from "../../../components/pageWrappers/merchantPageWrapper/merchantPageWrapper";
import ProtectedWrapper from "../../../components/pageWrappers/protectedWrapper/protectedWrapper";
import {MERCHANT_ROLE} from "../../../helpers/constants/roles";
import {getMarketplace} from "../../../api/marketplaceAPI";

const MarketplacePage = () => {
	const { marketplaceId } = useParams();
	const navigate = useNavigate();
	const [marketplace, setMarketplace] = useState(null);
	const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);
	const [viewData, setViewData] = useState({
		name: '',
		description: '',
		logo: ''
	});

	console.log(marketplace);

	const getCurrentMarketplace = async () => {
		await getMarketplace(marketplaceId)
		.then(res => {
			setMarketplace(res);

			if (res.status === 'draft') {
				setViewData({
					name: res?.unverifiedChanges?.name || res?.name,
					description: res?.unverifiedChanges?.description || res?.description,
					logo: res?.unverifiedLogoUrl || res.logoUrl
						? `${window?.__CONFIG__?.REACT_APP_BASE_URL}${res?.unverifiedLogoUrl || res.logoUrl}`
						: null,
				})
			} else {
				setViewData({
					name: res?.unverifiedChanges?.name || res.name,
					description: res?.unverifiedChanges?.description || res.description,
					logo: res?.unverifiedLogoUrl || res.logoUrl
						? `${window?.__CONFIG__?.REACT_APP_BASE_URL}${res?.unverifiedLogoUrl || res.logoUrl}`
						: null,
				})
			}
		})
	};

	useEffect(() => {
		getCurrentMarketplace();
	}, []);

	const statusBuilder = (status) => {
		switch (status) {
			case 'draft':
				return <Badge bg="danger">Черновик бизнеса</Badge>;
			case 'verification':
				return <Badge bg="warning" text="dark">В процессе верификации</Badge>;
			case 'published':
				return <Badge bg="success">Бизнес активен</Badge>;
		}
	};

	const moveToDrafthandler = async () => {
		marketplace.unpublish = true;
		await marketplace.save()
		.then(() => {
			getCurrentMarketplace()
		})
	};

	const moveToVerification = async () => {
		marketplace.markForVerification = true;
		await marketplace.save()
		.then(() => {
			getCurrentMarketplace()
		})
	}

	const deleteHndler = async () => {
		await marketplace.destroy()
		.then(() => {
			window.location.pathname = '/merchant';
		})
	}

	return (
		<ProtectedWrapper role={MERCHANT_ROLE}>
			<MerchantPageWrapper>
				{marketplace
					? (
						<>
							<Modal show={isOpenDeleteModal} onHide={() => setOpenDeleteModal(false)}>
								<Modal.Header closeButton>
									<Modal.Title>Удаление бизнеса</Modal.Title>
								</Modal.Header>
								<Modal.Body>
									Вы действительно хотите удалить бизнес?
								</Modal.Body>
								<Modal.Footer>
									<Button variant="secondary" onClick={() => deleteHndler()}>
										Удалить
									</Button>
									<Button variant="danger" onClick={() => setOpenDeleteModal(false)}>
										Отмена
									</Button>
								</Modal.Footer>
							</Modal>
							<div className="flex-space-between">
								<h1>{viewData?.unverifiedChanges?.name ||  viewData.name}</h1>
								<div>
									Статус проверки: {statusBuilder(marketplace.status)}
								</div>
							</div>
							<div className='block marginBottom20 marginTop10'>
								Рейтинг вашего магазина: <b>{marketplace?.rating?.score || '0'} / 5</b> (голосов: {marketplace?.rating?.votes || '0'})
							</div>
							{viewData.logo && (
								<img
									src={viewData.logo || ''}
									alt={viewData.name}
									style={{width: '150px', marginBottom: '20px'}}
								/>
							)}
							<p className="marginBottom20">{viewData?.unverifiedChanges?.description || viewData.description}</p>
							<div className="flex-space-between">
								<div className='flex'>
									<Button
										variant="dark"
										onClick={() => {navigate(`/merchant/${marketplaceId}/marketplace/edit`)}}
									>Редактировать</Button>
									{marketplace.status === 'draft' ? (
										<Button
											variant="dark"
											className="marginLeft20"
											onClick={() => moveToVerification()}
										>Отправить на верификацию</Button>
									) : (
										<Button
											variant="dark"
											className="marginLeft20"
											onClick={() => moveToDrafthandler()}
										>Перенести в черновики</Button>
									)}
								</div>
								<Button
									variant="danger"
									className="marginLeft20"
									onClick={() => setOpenDeleteModal(true)}
								>Удалить бизнес</Button>
							</div>
						</>
					) : (
						<Spinner style={{ position: "absolute", top: '50%', left: '50%'}} />
					)
				}
			</MerchantPageWrapper>
		</ProtectedWrapper>
	)
}

export default MarketplacePage