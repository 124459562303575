import {attr, belongsTo} from "spraypaint";
import ApplicationRecord from "./baseModel";

const MarketplaceCouponsModel = ApplicationRecord.extend({
	static: { jsonapiType: "marketplace_coupons" },
	attrs: {
		id: attr(),
		createdAt: attr({ persist: false }),
		updatedAt: attr({ persist: false }),
		marketplace: belongsTo(),
		coupon: belongsTo(),
	}
})

export default MarketplaceCouponsModel